<template>
  <div
    id="login-view"
    v-bind:style="{ 'background-image': 'url(' + bgUrl + ')' }"
  >
    <div class="logo-group">
      <!-- <img src="~@/assets/futsu-logo.png" id="futsu-logo" /> -->
      <!-- <img src="~@/assets/lin-logo.png" id="lin-logo" /> -->
      <!-- <img src="~@/assets/cec-logo.png" id="cec-logo" /> -->
    </div>
    <template v-if="isLogin">
      <span id="nt" class="spot-name" @click="setInfo('nt')"></span>
      <span id="g12" class="spot-name" @click="setInfo('g12')"></span>
      <span id="g11" class="spot-name" @click="setInfo('g11')"></span>
      <span id="g10" class="spot-name" @click="setInfo('g10')"></span>
      <span id="g09" class="spot-name" @click="setInfo('g09')"></span>
      <span id="g08" class="spot-name" @click="setInfo('g08')"></span>
      <span id="g07" class="spot-name" @click="setInfo('g07')"></span>

      <div id="btn-nt" class="btn-toggle" @click="setInfo('nt')"></div>
      <div id="btn-g12" class="btn-toggle" @click="setInfo('g12')"></div>
      <div id="btn-g11" class="btn-toggle" @click="setInfo('g11')"></div>
      <div id="btn-g10" class="btn-toggle" @click="setInfo('g10')"></div>
      <div id="btn-g09" class="btn-toggle" @click="setInfo('g09')"></div>
      <div id="btn-g08" class="btn-toggle" @click="setInfo('g08')"></div>
      <div id="btn-g07" class="btn-toggle" @click="setInfo('g07')"></div
    ></template>
    <div class="form-block" v-if="!isLogin">
      <div class="login-form">
        <h1>系統登入</h1>
        <input type="text" placeholder="帳號" v-model="loginForm.account" />
        <input
          type="password"
          placeholder="密碼"
          v-model="loginForm.password"
          @keyup.enter="login()"
        />
        <button class="btn-login" @click="login()">
          <span>登入</span>
        </button>
      </div>
    </div>

    <div
      class="station-info"
      v-if="Object.keys(stationInfo).length > 0"
      @click="goStation(stationInfo.key)"
    >
      <div class="header">
        <span class="station-name">{{ stationInfo.name }}</span>
        <button class="btn-close-window" @click="clearInfo"></button>
      </div>
      <hr />
      <p
        class="station-device"
        v-for="device in stationInfo.device"
        :key="device"
      >
        {{ device }}
      </p>
    </div>
    <button class="btn-more" v-if="isLogin">
      <span>請點擊您希望進一步了解的站體</span>
    </button>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { goLogin } from '@/api/index.js'
export default {
  data() {
    return {
      loginForm: {
        account: '',
        password: '',
      },
      timestamp: '',
      stationInfo: {},
      bgUrl: '',
      windowWidth: window.innerWidth,
    }
  },
  created() {
    this.getBg('g07')
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    ...mapState(['isLogin', 'stationInfoList']),
  },
  methods: {
    getBg(spot) {
      if (this.isLogin) {
        if (this.windowWidth < 768) {
          this.bgUrl = require('../assets/login-bg-' + spot + '-mb.png')
        } else {
          this.bgUrl = require('../assets/login-bg-' + spot + '.png')
        }
      } else {
        if (this.windowWidth < 768) {
          this.bgUrl = require('../assets/unlogin-bg-mb.png')
        } else {
          this.bgUrl = require('../assets/unlogin-bg.png')
        }
      }
    },
    ...mapMutations(['setAccessToken', 'setIsLogin', 'setSidebarOpen']),
    login() {
      goLogin({
        account: this.loginForm.account,
        password: this.loginForm.password,
      })
        .then((res) => {
          this.setAccessToken(res.data.access_token)
          this.setIsLogin(true)
          if (this.windowWidth < 768) {
            this.$router.push({ name: 'Map', params: { key: 'g07' } })
          } else {
            this.getBg('g07')
          }
        })
        .catch(() => {
          this.setIsLogin(false)
          alert('登入失敗')
        })
    },
    setInfo(key) {
      if (!this.isLogin) return
      // if (Object.keys(this.stationInfo).length > 0) {
      //   this.goStation(key)
      // }
      this.getBg(key)
      this.stationInfo = this.stationInfoList.find((e) => e.key === key)

      console.log('stationInfo', this.stationInfo, this.windowWidth)
    },
    clearInfo() {
      this.stationInfo = {}
    },
    goStation(key) {
      if (!this.isLogin) return
      this.$router.push({ name: 'Map', params: { key: key } })
    },
  },
}
</script>
